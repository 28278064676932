import './header.scss'

import { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import i18n, { LowerLanguage, lowerLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import { useGetProduct, useGetUserTransaction } from 'src/networking/terminals'
import { atomProduct, atomUserQuote } from 'src/storage'

export const useHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const urlSearchParams = new URLSearchParams(useLocation().search)
  const notifierId = urlSearchParams.get('quote_id') || undefined

  const { crossGate: getProduct } = useGetProduct()
  const { crossGate: getUserTransaction } = useGetUserTransaction()

  const [product, saveProduct] = useRecoilState(atomProduct)
  const [userQuote, saveUserQuote] = useRecoilState(atomUserQuote)

  const [radioLanguage, setRadioLanguage] = useState(lowerLanguage)

  useEffect(() => {
    if (notifierId && !userQuote) {
      getUserTransaction(
        {
          body: {
            notifierId: notifierId,
          },
        },
        {
          onSuccess: (quoteUserTransaction) => {
            if (quoteUserTransaction) {
              saveUserQuote(quoteUserTransaction)

              setRadioLanguage(
                quoteUserTransaction?.language?.toLowerCase() as SetStateAction<'it' | 'de' | 'en'>
              )
              i18n.changeLanguage(quoteUserTransaction?.language?.toLowerCase())

              if (!product) {
                getProduct(
                  {
                    params: {
                      agencyCode: quoteUserTransaction?.agency?.id?.toUpperCase(),
                    },
                  },
                  {
                    onSuccess: (product) => {
                      saveProduct(product)
                    },
                  }
                )
              }
            }
          },
        }
      )
    }
  }, [notifierId, userQuote])

  /**
   * Functions
   */
  const onChange = (language: LowerLanguage) => {
    setRadioLanguage(language)
    i18n.changeLanguage(language)
    setTimeout(() => window.location.reload())
  }

  const setIT = () => onChange('it')
  const setDE = () => onChange('de')
  const setEN = () => onChange('en')

  const goToDataEntry = () => {
    navigate({
      pathname: Paths.DataEntry1,
      search: `?${new URLSearchParams({
        base: 'true',
        seasonal: 'false',
      }).toString()}`,
    })
  }

  const goToLanding = () => navigate(Paths.Landing)

  return { t, radioLanguage, setIT, setDE, setEN, goToDataEntry, goToLanding }
}
