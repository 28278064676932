import './data-entry-step4.scss'

import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { AxeptaWindow } from 'src/types'

export const useDataEntryStep4 = () => {
  const urlSearchParams = new URLSearchParams(useLocation().search)
  const paymentId = urlSearchParams.get('paymentId') || ''

  useEffect(() => {
    if (paymentId) {
      const axeptaWindow: AxeptaWindow = window
      axeptaWindow?.callAxeptaClient?.()?.preparePayment?.(paymentId)
    }
  }, [paymentId])

  return {}
}
