import './landing.scss'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useResetRecoilState } from 'recoil'
import { atomCurrentStep, atomFormikMainInsured, atomQuote, atomUserQuote } from 'src/storage'

export const useLanding = () => {
  const resetFormikMainInsured = useResetRecoilState(atomFormikMainInsured)
  const resetQuote = useResetRecoilState(atomQuote)
  const resetUserQuote = useResetRecoilState(atomUserQuote)
  const resetCurrentStep = useResetRecoilState(atomCurrentStep)

  const { t } = useTranslation()

  useEffect(() => {
    resetFormikMainInsured()
    resetCurrentStep()
    resetUserQuote()
    resetQuote()
  }, [])

  return { t }
}
