import { MOCKEND_ALL_DISABLED, MOCKEND_TIMING } from 'src/config'
import { MockendConfig } from 'src/types'

import getCookie from './mocks/getCookie.json'
import getProduct from './mocks/getProduct.json'
import getSandwiches from './mocks/getSandwiches.json'
import getSandwiches500 from './mocks/getSandwiches500.json'
import getUserTransaction from './mocks/getUserTransaction.json'
import postBooking from './mocks/postBooking.json'
import postCheckClaimCode from './mocks/postCheckClaimCode.json'
import postEvaluatePremium from './mocks/postEvaluatePremium.json'
import postInjuryClaim from './mocks/postInjuryClaim.json'
import postPayment from './mocks/postPayment.json'
import postQuote from './mocks/postQuote.json'

export const ALL_DISABLED = MOCKEND_ALL_DISABLED
export const ALL_ENABLED = true
export const TIMING = MOCKEND_TIMING

export const CONFIG: MockendConfig = {
  // Start showcase: remove it when feel like a bomber 🚀🚀🚀
  getSandwiches: {
    enabled: true,
    status: 200,
    mocks: {
      200: getSandwiches,
      500: getSandwiches500,
    },
  },
  postBooking: {
    enabled: true,
    status: 200,
    mocks: {
      200: postBooking,
    },
  },
  // End Showcase

  getProduct: {
    enabled: true,
    status: 200,
    mocks: {
      200: getProduct,
    },
  },
  postQuote: {
    enabled: true,
    status: 200,
    mocks: {
      200: postQuote,
    },
  },
  postEvaluatePremium: {
    enabled: true,
    status: 200,
    mocks: {
      200: postEvaluatePremium,
    },
  },
  getCookie: {
    enabled: true,
    status: 200,
    mocks: {
      200: getCookie,
    },
  },
  postCheckClaimCode: {
    enabled: true,
    status: 200,
    mocks: {
      200: postCheckClaimCode,
    },
  },
  postCreateClaim: {
    enabled: true,
    status: 200,
    mocks: {
      200: { outcome: true },
    },
  },
  postInjuryClaim: {
    enabled: true,
    status: 200,
    mocks: {
      200: postInjuryClaim,
    },
  },
  postSendEmailClaim: {
    enabled: true,
    status: 200,
    mocks: {
      200: { outcome: true },
    },
  },
  postUploadFileClaim: {
    enabled: true,
    status: 200,
    mocks: {
      200: { fileName: 'FILE_NAME.pdf' },
    },
  },
  getUserTransaction: {
    enabled: true,
    status: 200,
    mocks: {
      200: getUserTransaction,
    },
  },
  postPayment: {
    enabled: true,
    status: 200,
    mocks: {
      200: postPayment,
    },
  },
}
