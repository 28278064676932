import { DownloadIcon } from 'src/assets'
import { lowerLanguage } from 'src/i18n'
import { Document } from 'src/types'
import { Button } from 'src/ui/widgets'

import { useDocuments } from './documents.hook'

export const Documents = () => {
  const { t, documents, openDocument } = useDocuments()

  const Title = (
    <h3 className="ps-md-3 mb-4 documents-dynamic-size-title d-flex align-item-center text-uppercase">
      {t('landing.section_7_title')}
    </h3>
  )

  const Subtitle = (
    <div className="ps-md-3 mb-4 documents-dynamic-size-subtitle d-flex align-items-center white-space-pre-subtitle">
      {t('landing.section_7_subtitle')}
    </div>
  )

  const Document = ({ id, description, file }: Document) => (
    <div key={id}>
      {id && (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="documents-dynamic-size-document-title">
              {description?.[lowerLanguage]}
            </div>

            <Button
              text={t('commons.download')}
              iconRight={DownloadIcon}
              iconSize={18}
              flavor="transparentAccent"
              classes="p-0 d-flex align-items-center c-2727ab"
              classesText="font-20-ag-400 c-2727ab"
              classesIconRight="c-2727b2 ms-2"
              onPress={() => openDocument(file[lowerLanguage])}
            />
          </div>
          <div className="documents-separator" />
        </div>
      )}
    </div>
  )

  const Documents = (
    <div className="pt-4 pt-md-0">
      <div>{documents?.map(Document)}</div>
    </div>
  )

  return (
    <div className="p-5 ps-3 pe-3 d-flex justify-content-center container-fluid bg-ffffff">
      <div className="pt-md-5 mt-md-3 col-12 px-md-1 row justify-content-center m-0 p-0">
        <div className="col-lg-10 d-md-flex px-md-3">
          <div className="col-md-6 p-0 ps-md-4 ms-ms-2 pe-md-3">
            {Title}
            {Subtitle}
          </div>
          <div className="col-md-6 p-0 ps-md-4 ms-md-2">{Documents}</div>
        </div>
      </div>
    </div>
  )
}
//  <div className="col-xl-12 d-xl-flex justify-content-center">
// <div className="col-xl-6">
//   {Title}
//   {Subtitle}
// </div>
// <div className="col-xl-6">{}</div>
// </div>
